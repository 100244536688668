const AudienceData = [
    {
        id: 1,
        name: 'zerihun Tegenu',
        email: 'zerihun@gmail.com',
        age: 25,
        address: '22, Bole, Addis Abeba'
    },
    {
        id: 2,
        name: 'girma Tegenu',
        email: 'girma@gmail.com',
        age: 30,
        address: '22, Bole, Addis Abeba'
    },
    {
        id: 3,
        name: 'yonas Tegenu',
        email: 'yonas@gmail.com',
        age: 25,
        address: '22, Bole, Addis Abeba'
    },
    {
        id: 4,
        name: 'mahlet Tegenu',
        email: 'mahlet@gmail.com',
        age: 25,
        address: '22, Bole, Addis Abeba'
    },
    {
        id: 5,
        name: 'yonas Tegenu',
        email: 'yonas@gmail.com',
        age: 25,
        address: '22, Bole, Addis Abeba'
    },
    {
        id: 6,
        name: 'mahlet Tegenu',
        email: 'mahlet@gmail.com',
        age: 25,
        address: '22, Bole, Addis Abeba'
    },
    {
        id: 7,
        name: 'zerihun Tegenu',
        email: 'zerihun@gmail.com',
        age: 30,
        address: '22, Jemo, Addis Abeba'
    },
    {
        id: 8,
        name: 'girma Tegenu',
        email: 'girma@gmail.com',
        age: 30,
        address: '22, Bole, Addis Abeba'
    },
    {
        id: 9,
        name: 'yonas Tegenu',
        email: 'yonas@gmail.com',
        age: 25,
        address: '22, Jemo, Addis Abeba'
    },
    {
        id: 10,
        name: 'mahlet Tegenu',
        email: 'mahlet@gmail.com',
        age: 25,
        address: '22, Bole, Addis Abeba'
    },
    {
        id: 11,
        name: 'yonas Tegenu',
        email: 'yonas@gmail.com',
        age: 25,
        address: '22, Bole, Addis Abeba'
    },
    {
        id: 12,
        name: 'mahlet Tegenu',
        email: 'mahlet@gmail.com',
        age: 25,
        address: '22, Bole, Addis Abeba'
    },
    {
        id: 13,
        name: 'zerihun Tegenu',
        email: 'zerihun@gmail.com',
        age: 30,
        address: '22, Bole, Addis Abeba'
    }
];
export default AudienceData;
